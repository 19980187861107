<template>
  <div>
    <!-- <v-col> -->
    <v-app-bar
      v-if="!$route.meta.hideNavigation"
      :key="$route.path"
      color="secondary"
      dark
      :dir="$i18n.locale == 'ar' || $i18n.locale == 'kr' ? 'rtl' : 'ltr'"
    >
      <v-btn
      v-if="$store.state.user.role != 'Guest'"
        @click="drawer = !drawer"
        icon=""
        depressed=""
        style="border-radius: 15px"
        fab
        class="mx-4"
      >
        <v-icon large="">{{ drawer == false ? "menu" : "notes" }}</v-icon>
  </v-btn>

      <v-breadcrumbs
        dark
        :items="$route.meta.breadcrumbs"
        :dir="$i18n.locale == 'ar' || $i18n.locale == 'kr' ? 'rtl' : 'ltr'"
        class="hidden-sm-and-down"
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            <h3 :class="item.disabled ? 'third--text' : 'white--text'">
              {{ item.text }}
            </h3>
          </v-breadcrumbs-item>
        </template>
        <template v-slot:divider>
          <v-icon>mdi-chevron-left</v-icon>
        </template>
      </v-breadcrumbs>

      <!-- <h3 v-if="isMobile">{{ $route.name }}</h3> -->

      <v-spacer class="hidden-sm-and-down" />

      <!-- <v-spacer class="hidden-sm-and-down" /> -->
      <v-btn
        icon
        to="/emails"
        v-if="$store.state.user.role != 'User Managmenet'  && $store.state.user.role != 'Guest'"
      >
        <v-icon> fi fi-rr-envelope </v-icon>
      </v-btn>
      <notifications
        v-if="
          $store.state.user.role != 'Gate' &&
          $store.state.user.role != 'User Managmenet'
        "
      />
      <v-spacer class="hidden-md-and-up" />
      <fullscreen class="hidden-sm-and-down" />
      <themChanger class="hidden-sm-and-down" />
      <v-divider vertical="" inset />
      <profile />
      <locale />
    </v-app-bar>
    <!-- </v-col> -->
    <div v-intersect="onIntersect" />
    <drawer
      :drawer="drawer"
      v-if="
        (isMobile && !$route.meta.hideNavigation) || !$route.meta.hideDrawer
      "
    />
  </div>
</template>

<script>
export default {
  components: {
    drawer: () => import("./drawer.vue"),
    themChanger: () => import("./themeChanger"),
    notifications: () => import("./notifications"),
    fullscreen: () => import("./fullscreen"),
    profile: () => import("./profile"),
    locale: () => import("./locale.vue"),
    // tabs: () => import('./tabs')
  },
  data() {
    return {
      username: "",
      drawer: true,
      isIntersecting: false,
      isMobile: false,
    };
  },

  created() {
    this.onResize();
    // window.addEventListener("resize", this.onResize, {
    //   passive: true,
    // });
  },

  watch: {
    beforeDestroy() {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", this.onResize, {
          passive: true,
        });
      }
    },
  },

  methods: {
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting;
    },
    onResize() {
      this.isMobile = window.innerWidth < 1000;
      if (window.innerWidth < 1000) {
        this.drawer = false;
      }
    },
  },
};
</script>
